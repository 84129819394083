import React, { useEffect, useState } from 'react'
import Header from '../../components/UserComponents/Dashboard/Header'
import LeftSidebar from '../../components/UserComponents/Dashboard/LeftSidebar'
import { useLocation } from 'react-router-dom'
import Spinners from '../../components/Spinners/Spinners'
import "./MemberMapPage.css"
const CensusDataPage = () => {
    const location = useLocation()
    const [url, setUrl] = useState("")
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        setLoader(true)
        const cleanedPathname = window.location.pathname.replace(/\/$/, "");
        if (cleanedPathname === "/census-data-google-studio") {
            setUrl("https://pages.milliondollarsellers.com/census-data-google-studio/")
        } else if (cleanedPathname === "/airbnb-directory") {
            setUrl("https://pages.milliondollarsellers.com/airbnb-directory/")
        } else if (cleanedPathname === "/book-club") {
            setUrl("https://pages.milliondollarsellers.com/book-club/")
        } else if (cleanedPathname === "/employee-blocklist") {
            setUrl("https://pages.milliondollarsellers.com/employee-blocklist/")
        } else if (cleanedPathname === "/employee-hire") {
            setUrl("https://pages.milliondollarsellers.com/employee-hire/")
        } else if (cleanedPathname === "/employee-match") {
            setUrl("https://pages.milliondollarsellers.com/employee-match/")
        } else if (cleanedPathname === "/freelancers") {
            setUrl("https://pages.milliondollarsellers.com/freelancers/")
        } else if (cleanedPathname === "/gift-guide") {
            setUrl("https://pages.milliondollarsellers.com/gift-guide/")
        } else if (cleanedPathname === "/team") {
            setUrl("https://pages.milliondollarsellers.com/team/")
        } else if (cleanedPathname === "/mds-channels") {
            setUrl("https://pages.milliondollarsellers.com/mds-channels/")
        } else if (cleanedPathname === "/member-link") {
            setUrl("https://pages.milliondollarsellers.com/Member-Link/")
        } else if (cleanedPathname === "/photos") {
            setUrl("https://pages.milliondollarsellers.com/photos/")
        } else if (cleanedPathname === "/values") {
            setUrl("https://pages.milliondollarsellers.com/values/")
        } else if (cleanedPathname === "/squads") {
            setUrl("https://pages.milliondollarsellers.com/squads/")
        } else if (cleanedPathname === "/coffee-chats") {
            setUrl("https://pages.milliondollarsellers.com/coffee-chats/")
        } else if (cleanedPathname === "/member-score") { 
            setUrl("https://pages.milliondollarsellers.com/member-score/")
        } else if (cleanedPathname === "/chapters") { 
            setUrl("https://pages.milliondollarsellers.com/chapters/")
        }else if (cleanedPathname === "/referral") { 
            setUrl("https://pages.milliondollarsellers.com/referral/")
        } else if (cleanedPathname === "/mds-merch") { 
            setUrl("https://pages.milliondollarsellers.com/mds-merch/")
        }
    }, [location])
    return (
        <>
            <Header />
            <div className="all-content-main-section">
                <LeftSidebar />
                <div className="all-content-main-edit-profile all-content-main-edit-profile"  >
                    {loader && <div className='iframe-loader'> <Spinners type='brand' /> </div>}
                    <iframe style={{ display: `${loader ? "none" : "block"}` }} className="ifrane-wordpress scrollbar" onLoad={() => { setLoader(false); }} src={url} />
                </div>
            </div>
        </>
    )
}

export default CensusDataPage