import React, { useEffect, useState } from "react";
import Logo from "../../../Images/logo_mds_small.svg";
import TextField from "../../InputField/TextField";
import Button from "../../Button/Button";
import authService from "../../../Services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader2 from "../../../Images/loader2.gif";
const CheckEmail = () => {
  const navigate = useNavigate();
  const [emailErr, setEmailErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [mdsEmail, setMdsEmail] = useState("");
  const [verificationCount, setVerficationCount] = useState(0);
  const location = useLocation();
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  useEffect(() => {
    if (verificationCount === 2) {
      navigate("/contact-support");
    }
  }, [verificationCount]);
  return (
    <>
      <div className="container-fluid">
        <div className="new-login-box-wrapper">
          <div className="header-collaborator header-spacing">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div className="collaborator-main new-email-verify">
            <div className="collaborator-succesfully">
              <div className="collaborator-info">
                <div>
                  <h2 className="collaborator-h2 mb-2">Oops!</h2>
                  <h6 className="collaborator-h6">
                    The email you provided is not linked to MDS. Please enter
                    your MDS account email.
                  </h6>
                </div>
                <TextField
                  placeholder="Enter preferred email"
                  hasLabel
                  label=""
                  error={emailErr}
                  hasRightImg={false}
                  value={mdsEmail}
                  handleChange={(e) => {
                    setMdsEmail(e.target.value);
                    if (e.target.value.trim() === "") {
                      setEmailErr("Please enter your mds preferred email!");
                    } else if (!emailRegex.test(e.target.value)) {
                      setEmailErr("Please enter valid email!");
                    } else {
                      setEmailErr("");
                    }
                  }}
                />

                <Button
                  variant={loader ? "secondary" : "primary"}
                  handleClick={(e) => {
                    if (mdsEmail.trim() === "") {
                      setEmailErr("Please enter your mds preferred email!");
                    } else if (!emailRegex.test(mdsEmail)) {
                      setEmailErr("Please enter valid email!");
                    } else {
                      setEmailErr("");
                      setLoader(true);
                      authService
                        .userExist({
                          auth0Id: location.state.authId,
                          socialEmail: location.state.email,
                          mdsEmailExists: true,
                          mdsEmail: mdsEmail,
                        })
                        .then((res) => {
                          if (!res.data.mdsEmailDBExists) {
                            setVerficationCount(verificationCount + 1);
                            setEmailErr(res?.data?.message||"Email doesn’t match. Try again.");
                            setLoader(false);
                          } else {
                            navigate("/email-verified", { state: mdsEmail });
                            setEmailErr("");
                            setLoader(false);
                          }
                        });
                    }
                  }}
                  disabled={loader}
                  label={
                    loader ? (
                      <img
                        className="btn-loader-img-cover"
                        src={Loader2}
                        width={60}
                        height={20}
                      />
                    ) : (
                      "Continue"
                    )
                  }
                />

                <p>
                  Not a member?{" "}
                  <a href="https://www.milliondollarsellers.com/apply-step-1">
                    Apply now
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckEmail;
